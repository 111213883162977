import React, { useEffect, Fragment } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/bundle';

import {
  Layout,
  SEO,
  FormHero,
  Section,
  Container,
  Grid,
  Col,
  Flex,
  Overlay,
  AnchorText,
  SanityBlockContent,
  FadeIn,
} from '../components';

import { useSanity } from '../hooks';

export const query = graphql`
  query LocationTemplateQ(
    $slug: String
    $heroBgImgSlug: String
    $reviewImg1Slug: String
    $reviewImg2Slug: String
    $reviewImg3Slug: String
  ) {
    page: googlePagesSheet(slug: { eq: $slug }) {
      id
      pageType
      indexStatus
      company
      phoneNumber
      canonical
      city
      cityState
      cityStateZip
      stateAbb
      zip
      mapUrl
      mapEmbed
      keyword1
      keyword2
      keyword3
      keyword4
      keyword5
      audience
      slug
      heroBgImgSlug
      heroBgImgAlt
      reviewImg1Slug
      reviewImg1Alt
      reviewImg2Slug
      reviewImg2Alt
      reviewImg3Slug
      reviewImg3Alt
      residentialHeaderTagline
      residentialHeaderHeadline
      residentialHeaderParagraph
      commercialHeaderTagline
      commercialHeaderHeadline
      commercialHeaderParagraph
    }
    bgImg: allFile(
      filter: {
        relativeDirectory: { eq: "assets/images/locations/hero" }
        name: { eq: $heroBgImgSlug }
      }
    ) {
      nodes {
        id
        name
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: WEBP)
        }
      }
    }
    reviewImg1: allFile(
      filter: {
        relativeDirectory: { eq: "assets/images/locations/reviews/1" }
        name: { eq: $reviewImg1Slug }
      }
    ) {
      nodes {
        id
        name
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: WEBP)
        }
      }
    }
    reviewImg2: allFile(
      filter: {
        relativeDirectory: { eq: "assets/images/locations/reviews/2" }
        name: { eq: $reviewImg2Slug }
      }
    ) {
      nodes {
        id
        name
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: WEBP)
        }
      }
    }
    reviewImg3: allFile(
      filter: {
        relativeDirectory: { eq: "assets/images/locations/reviews/3" }
        name: { eq: $reviewImg3Slug }
      }
    ) {
      nodes {
        id
        name
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;

const StyledHeroHeadline = styled(motion.h1)`
  color: ${(props) => props.$headline};
`;

const StyledHeroSubheadline = styled(motion.div)`
  color: ${(props) => props.$subheadline};
`;

const StyledCheckIcon = styled.i`
  color: ${(props) => props.$color} !important;
  &:hover {
    color: ${(props) => props.$colorHover} !important;
  }
`;

const StyledContainer = styled((props) => <Container {...props} />)`
  color: ${(props) => props.$color} !important;
  background-color: ${(props) => props.$bgColor} !important;
  & h2 {
    color: ${(props) => props.$h2Color} !important;
  }
  & h3 {
    color: ${(props) => props.$h3Color} !important;
    :hover {
      color: ${(props) => props.$h3ColorHover} !important;
    }
    & span {
      color: ${(props) => props.$spanColor} !important;
    }
  }
`;

const StyledTagline = styled.span`
  color: ${(props) => props.$spanColor};
`;

const LocationTemplate = function (props) {
  const { data } = props;
  const page = data && data?.page;
  const bgImg = data && data?.bgImg?.nodes;
  const reviewImg1 = data && data?.reviewImg1?.nodes;
  const reviewImg2 = data && data?.reviewImg2?.nodes;
  const reviewImg3 = data && data?.reviewImg3?.nodes;

  const {
    siteSEO,
    info,
    colors,
    hero,
    primary,
    secondary,
    accent,
    neutral,
    asset,
    services,
    googleReviews,
  } = useSanity();

  const variants = {
    hidden: { opacity: 0, scale: 0.8, x: -100 },
    visible: { opacity: 1, scale: 1, x: 0 },
  };

  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
    if (!inView) {
      controls.start('hidden');
    }
  }, [controls, inView]);

  const pageSEO = {
    title: `${page?.cityState} ${page?.keyword1} & ${page?.keyword2}`,
    description: `Need ${page?.keyword1} & ${page?.keyword2} services in ${page?.cityState}? We offer affordable residential, commercial, and municipal pest control services.`,
    canonical: page?.canonical,
    schema: page?.schema,
  };

  return (
    <Layout
      pageType={page?.pageType}
      navPhone={page?.phoneNumber}
      navAddress={page?.cityState}
      mapUrl={page?.mapUrl}
    >
      <SEO
        title={pageSEO?.title}
        description={pageSEO?.description}
        canonical={pageSEO?.canonical}
      >
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "${page?.company}",
              "image": "https://cdn.sanity.io/images/0hnhvqzj/production/dfffeb7cf334351c19f5901910ee8a2c52ba2f31-500x159.png?w=173&h=55&auto=format",
              "@id": "https://www.pilgrimpestpro.com/${page?.slug}/",
              "url": "https://www.pilgrimpestpro.com/${page?.slug}/",
              "telephone": "${page?.phoneNumber}",
              "priceRange": "99-500",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "",
                "addressLocality": "${page?.city}",
                "addressRegion": "${page?.stateAbb}",
                "postalCode": "0${page?.zip}",
                "addressCountry": "US"
              },
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday"
                ],
                "opens": "00:00",
                "closes": "23:59"
              },
              "sameAs": "https://www.facebook.com/PilgrimPest"
            }
          `}
        </script>
      </SEO>
      <Helmet>
        <meta
          name="robots"
          content={
            siteSEO?.indexStatus === 'noIndex'
              ? 'noindex, nofollow'
              : page?.indexStatus === 'index'
              ? 'index, follow'
              : 'noindex, nofollow'
          }
        />
      </Helmet>
      {/* SECTION: hero */}
      <Section classes="relative flex flex-col place-content-center place-items-center lg:h-screen justify">
        {/* COMPONENT: background image */}
        <div className="absolute inset-0">
          {bgImg
            .filter((item) => item?.name === page?.heroBgImgSlug)
            .map((item) => (
              <Fragment key={item?.id}>
                <GatsbyImage
                  image={item?.childImageSharp?.gatsbyImageData}
                  alt={page?.heroBgImgAlt}
                  loading="eager"
                  className="h-full w-full object-cover"
                />
                <Overlay bgColor={hero?.overlay?.color} />
              </Fragment>
            ))}
        </div>
        {/* COMPONENT: 2 column grid */}
        <Grid classes="lg:grid-cols-2 gap-8 max-w-7xl my-6 lg:mb-0 lg:mt-12">
          {/* COMPONENT: text content */}
          <Flex classes="bg-gray-900/60 bg-clipping-path backdrop-filter backdrop-blur-lg mx-4 lg:mx-0 mt-12 lg:mt-24 p-6 lg:p-10 space-y-6 flex-col justify-center relative text-left rounded-xl shadow-xl sm:max-w-md md:max-w-lg lg:max-w-none">
            <StyledHeroHeadline
              ref={ref}
              variants={variants}
              initial="hidden"
              animate={controls}
              transition={{ duration: 0.5, delay: 0 }}
              $headline={hero?.headline?.color}
              className="m-0 text-center text-3xl font-extrabold capitalize tracking-tight sm:text-4xl lg:text-left lg:text-5xl"
            >
              {pageSEO?.title}
            </StyledHeroHeadline>
            <StyledHeroSubheadline
              ref={ref}
              variants={variants}
              initial="hidden"
              animate={controls}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="max-w-3xl space-y-4 text-lg lg:text-xl"
              $subheadline={hero?.tagline?.color}
            >
              <div className="">
                <span>
                  Pilgrim Pest Professionals is a{' '}
                  <AnchorText type="internal" to="home">
                    pest control company
                  </AnchorText>{' '}
                  serving residents & businesses in {page?.cityStateZip}.
                </span>
              </div>
              <ul className="fa-ul text-left">
                <li className="">
                  <span className="fa-li">
                    <StyledCheckIcon
                      $color={accent.default.color}
                      $colorHover={accent.default.color}
                      className="fas fa-check"
                    ></StyledCheckIcon>
                  </span>
                  <strong className="text-red-100">24/7 Pest Control</strong>{' '}
                  <span>Emergency Services</span>
                </li>
                <li className="">
                  <span className="fa-li">
                    <StyledCheckIcon
                      $color={accent.default.color}
                      $colorHover={accent.default.color}
                      className="fas fa-check"
                    ></StyledCheckIcon>
                  </span>
                  <strong className="text-red-100">
                    Residential, Commercial, Municipal
                  </strong>{' '}
                  <span>Services</span>
                </li>
                <li className="">
                  <span className="fa-li">
                    <StyledCheckIcon
                      $color={accent.default.color}
                      $colorHover={accent.default.color}
                      className="fas fa-check"
                    ></StyledCheckIcon>
                  </span>
                  <strong className="text-red-100">Family-Owned</strong>{' '}
                  <span>& Operated Business</span>
                </li>
                <li className="">
                  <span className="fa-li">
                    <StyledCheckIcon
                      $color={accent.default.color}
                      $colorHover={accent.default.color}
                      className="fas fa-check"
                    ></StyledCheckIcon>
                  </span>
                  <strong className="text-red-100">Licensed & Insured</strong>,{' '}
                  <span>Fast & Affordable</span>
                </li>
              </ul>
              <p className="italic">
                Call{' '}
                <AnchorText type="external" href={`tel:${page?.phoneNumber}`}>
                  {page?.phoneNumber}
                </AnchorText>{' '}
                or fill out our form to get started!
              </p>
            </StyledHeroSubheadline>
          </Flex>
          {/* COMPONENT: contact form */}
          <Flex classes="flex-col justify-center relative mx-auto lg:max-w-lg mt-6 lg:mt-24">
            <div className="overflow-hidden">
              <FormHero formName="form-hero" />
            </div>
          </Flex>
        </Grid>
      </Section>
      {/* SECTION: reviews */}
      <Section
        padding="lg"
        bgColor={neutral?.light?.color}
        h2Color={neutral?.darker?.color}
        h3Color={primary?.dark?.color}
        $spanColor={accent?.light?.color}
      >
        <Container classes="text-center">
          <StyledTagline
            $spanColor={accent?.dark?.color}
            className="text-base font-semibold uppercase tracking-wider"
          >
            {page?.reviewHeaderTagline}
          </StyledTagline>
          <h2 className="mx-auto mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl lg:max-w-xl">
            Pest Control & Wildlife Removal Services With Guaranteed Results
          </h2>
          <div className="mx-auto mt-6 max-w-prose text-xl text-zinc-600">
            We are proud to offer local residents and businesses affordable pest
            management services. We guarantee 1-2 years of pest-free living!
          </div>
          <Container padding="none" classes="pt-12 lg:pt-24">
            <Swiper
              slidesPerView={2}
              directions="horizontal"
              spaceBetween={30}
              loop={true}
              loopedSlides={0}
              slideClass="swiper-slide"
              pagination={{
                bulletActiveClass: 'swiper-pagination-bullet-active',
                clickable: true,
              }}
              speed={5000}
              centeredSlides={true}
              autoplay={{
                delay: 1,
                disableOnInteraction: true,
              }}
              modules={[Pagination, Autoplay]}
              className="relative flex h-full w-full items-center justify-center"
            >
              <SwiperSlide className="lg:mb-4">
                {reviewImg1
                  .filter((item) => item?.name === page?.reviewImg1Slug)
                  .map((item) => (
                    <Fragment key={item?.id}>
                      <GatsbyImage
                        image={item?.childImageSharp.gatsbyImageData}
                        alt={page?.reviewImg1Alt}
                        className="aspect-w-12 aspect-h-7 h-full w-full object-cover"
                      />
                    </Fragment>
                  ))}
              </SwiperSlide>
              <SwiperSlide className="lg:mb-4">
                {reviewImg2
                  .filter((item) => item?.name === page?.reviewImg2Slug)
                  .map((item) => (
                    <Fragment key={item?.id}>
                      <GatsbyImage
                        image={item?.childImageSharp.gatsbyImageData}
                        alt={page?.reviewImg2Alt}
                        className="aspect-w-12 aspect-h-7 h-full w-full object-cover"
                      />
                    </Fragment>
                  ))}
              </SwiperSlide>
              <SwiperSlide className="lg:mb-4">
                {reviewImg3
                  .filter((item) => item?.name === page?.reviewImg3Slug)
                  .map((item) => (
                    <Fragment key={item?.id}>
                      <GatsbyImage
                        image={item?.childImageSharp.gatsbyImageData}
                        alt={page?.reviewImg3Alt}
                        className="aspect-w-12 aspect-h-7 h-full w-full object-cover"
                      />
                    </Fragment>
                  ))}
              </SwiperSlide>
            </Swiper>
          </Container>
        </Container>
      </Section>
      {/* SECTION: pest control services */}
      <Section
        padding="lg"
        bgColor={neutral?.lighter?.color}
        h2Color={neutral?.darker?.color}
        h3Color={primary?.dark?.color}
        $spanColor={accent?.light?.color}
      >
        <Container classes="text-center">
          <StyledTagline
            $spanColor={accent?.dark?.color}
            className="text-base font-semibold uppercase tracking-wider"
          >
            {page?.residentialHeaderTagline}
          </StyledTagline>
          <h2 className="mx-auto mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl lg:max-w-xl">
            Pests We Exterminate throughout {page?.cityState}
          </h2>
          <div className="mx-auto mt-6 max-w-prose text-xl text-zinc-600">
            Our team of exterminators is experienced in handling all sorts of
            pests, so you can rest assured knowing that your problem will be
            solved as quickly and efficiently as possible.{' '}
          </div>
          <Grid classes="mt-20 gap-3 grid-cols-2 lg:grid-cols-4">
            {services
              .filter((service) => service?.tag?.title === 'pest')
              .map((service) => (
                <AnchorText to={service?.link?.slug?.current}>
                  <Col
                    key={service?.name}
                    classes="pt-6 relative w-full mx-auto px-4 sm:px-6 lg:px-8"
                  >
                    <Container padding="none" classes="">
                      <div className="h-full w-full rounded-full border-2 border-red-400 bg-white object-cover p-4 shadow-2xl transition hover:scale-105 lg:p-8">
                        <div className="rounded-full">
                          <GatsbyImage
                            image={service?.image?.asset?.gatsbyImageData}
                            className="mx-auto h-full w-full object-cover"
                            alt={service?.image?.alt}
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </Container>
                    <div className="break-word relative z-10 text-center">
                      <h3 className="mt-8 text-xl font-medium lg:text-2xl">
                        {service?.name}
                      </h3>
                      <p className="mt-5 mb-8 text-left text-base text-zinc-500">
                        <SanityBlockContent blocks={service?._rawDescription} />
                      </p>
                    </div>
                  </Col>
                </AnchorText>
              ))}
          </Grid>
        </Container>
      </Section>
      {/* SECTION: pest control details */}
      <Section padding="lg" bgColor={neutral?.white?.color}>
        <div className="mx-auto max-w-7xl">
          <div className="grid grid-cols-1 gap-x-24 lg:grid-cols-12">
            <div className="lg:col-span-7">
              <article className="sanity-headlines space-y-6 px-4 text-lg lg:px-0">
                <h2>Our Pest Control Services</h2>
                <p>
                  Pests can quickly take over an area if they're not dealt with
                  early on, so it's important to partner up with a qualified
                  pest control company like Pilgrim Pest Professionals as soon
                  as you notice an issue.
                </p>
                <p>
                  We offer a variety of pest control services that will take
                  care of any unwanted guests in your home or business.
                </p>
                <ul className="fa-ul text-left font-semibold">
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    One-Time Pest Treatment
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    Pest Management for Specific Pests
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    Long-Term Pest Management Plans
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    Recreational Vehicle Preventative Treatments
                  </li>
                </ul>
                <p>
                  No matter what your situation is, we can help you find a
                  solution that works for you.
                </p>
                <p>
                  So if you're looking for a reliable, affordable, and
                  professional pest control company, look no further than
                  Pilgrim Pest Professionals.
                </p>
                <h2>Get Your Free Estimate</h2>
                <p>
                  Ready for our{' '}
                  <AnchorText type="internal" to="pest-control">
                    pest control services
                  </AnchorText>
                  ?
                </p>
                <p>
                  Call us today or fill out our form for your FREE estimate.
                  We'll be happy to answer any of your questions and help you
                  get started.
                </p>
              </article>
            </div>
            <div className="lg:col-span-5 lg:h-full lg:w-full">
              {/* <Sidebar sidebarType={sidebarType} /> */}
              <aside className="hidden pt-12 lg:flex lg:h-full lg:pt-0">
                <FadeIn classes="space-y-20 w-full lg:h-full">
                  {/* <FormHero formName="form-sidebar" /> */}
                  {/* <SidebarListServices /> */}
                  <div className="space-y-6 lg:sticky lg:top-44">
                    <StyledContainer
                      padding="none"
                      bgColor={primary?.dark?.color}
                      h2Color={accent?.default?.color}
                      h3Color={neutral?.lighter?.color}
                      h3ColorHover={accent?.default?.color}
                      spanColor={accent?.default?.color}
                      outerClasses="rounded-xl shadow-xl"
                      classes="relative p-3 lg:p-5 max-w-4xl mx-3 lg:mx-auto space-y-6"
                    >
                      <h2 className="text-center font-bold text-white">
                        Our Reviews
                      </h2>
                      {googleReviews
                        .filter(
                          (item, index) =>
                            item?.review == 5 &&
                            item?.reviewContent !== '   ' &&
                            index < 5
                        )
                        .slice(0, 3)
                        .map((item) => (
                          <div
                            key={item?.id}
                            className="space-y-6 rounded-xl bg-white p-2 shadow-xl lg:p-6"
                          >
                            <Grid classes="grid-cols-2">
                              <Col classes="text-left">
                                <div className="space-y-1">
                                  <p className="font-bold">{item?.name}</p>
                                  <p className="text-sm">{item?.reviewDate}</p>
                                </div>
                              </Col>
                              <Col classes="text-right">
                                <p className="font-bold">
                                  {/* {item?.review}{' '} */}
                                  <i className="fas fa-star text-amber-500"></i>
                                  <i className="fas fa-star text-amber-500"></i>
                                  <i className="fas fa-star text-amber-500"></i>
                                  <i className="fas fa-star text-amber-500"></i>
                                  <i className="fas fa-star text-amber-500"></i>
                                </p>
                                <AnchorText type="external" href={item?.query}>
                                  <span className="text-xs text-blue-900 hover:text-blue-500">
                                    Google Verified
                                  </span>
                                </AnchorText>
                              </Col>
                            </Grid>
                            <div>
                              <p className="italic">"{item?.reviewContent}"</p>
                            </div>
                          </div>
                        ))}
                    </StyledContainer>
                  </div>
                </FadeIn>
              </aside>
            </div>
          </div>
        </div>
      </Section>
      {/* SECTION: wildlife control services */}
      <Section
        padding="lg"
        bgColor={neutral?.lighter?.color}
        h2Color={neutral?.darker?.color}
        h3Color={primary?.dark?.color}
        $spanColor={accent?.light?.color}
      >
        <Container classes="text-center">
          <StyledTagline
            $spanColor={accent?.dark?.color}
            className="text-base font-semibold uppercase tracking-wider"
          >
            {page?.residentialHeaderTagline}
          </StyledTagline>
          <h2 className="mx-auto mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl lg:max-w-xl">
            Wildlife We Remove throughout {page?.cityState}
          </h2>
          <div className="mx-auto mt-6 max-w-prose text-xl text-zinc-600">
            Our team of wildlife experts is experienced in handling animals of
            all sizes, so you can rest assured knowing that your problem will be
            solved as quickly and efficiently as possible.
          </div>
          <Grid classes="mt-20 gap-3 grid-cols-2 lg:grid-cols-4">
            {services
              .filter((service) => service?.tag?.title === 'wildlife')
              .map((service) => (
                <AnchorText to={service?.link?.slug?.current}>
                  <Col
                    key={service?.name}
                    classes="pt-6 relative w-full mx-auto px-4 sm:px-6 lg:px-8"
                  >
                    <Container padding="none" classes="">
                      <div className="h-full w-full rounded-full border-2 border-red-400 bg-white object-cover p-4 shadow-2xl transition hover:scale-105 lg:p-8">
                        <div className="rounded-full">
                          <GatsbyImage
                            image={service?.image?.asset?.gatsbyImageData}
                            className="mx-auto h-full w-full object-cover"
                            alt={service?.image?.alt}
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </Container>
                    <div className="break-word relative z-10 text-center">
                      <h3 className="mt-8 text-xl font-medium lg:text-2xl">
                        {service?.name}
                      </h3>
                      <p className="mt-5 mb-8 text-left text-base text-zinc-500">
                        <SanityBlockContent blocks={service?._rawDescription} />
                      </p>
                    </div>
                  </Col>
                </AnchorText>
              ))}
          </Grid>
        </Container>
      </Section>
      {/* SECTION: wildlife control details */}
      <Section padding="lg" bgColor={neutral?.white?.color}>
        <div className="mx-auto max-w-7xl">
          <div className="grid grid-cols-1 gap-x-24 lg:grid-cols-12">
            <div className="lg:col-span-7">
              <article className="sanity-headlines space-y-6 px-4 text-lg lg:px-0">
                <h2>Our Wildlife Control Services</h2>
                <p>
                  Do you have a pesky critter problem? Are raccoons or squirrels
                  getting into your trash, or skunks digging through your
                  property?
                </p>
                <p>
                  If so, then you need our professional wildlife removal
                  services! Our team of experts can help solve your animal
                  issues quickly, effectively, and humanely.
                </p>
                <p>
                  We have the experience and knowledge necessary to get the job
                  done right, and we always take care to protect your property
                  and animals.
                </p>
                <p>
                  We offer a variety of solutions to meet your specific needs,
                  including:
                </p>

                <ul className="fa-ul text-left">
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Inspection</strong>: Our experienced and certified
                    technicians will first conduct a thorough inspection of your
                    property to identify the type of animal and how it’s getting
                    in.
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Removal</strong>: Our technicians are trained in the
                    latest and most effective removal techniques. We also use
                    state-of-the-art equipment to safely and humanely remove the
                    animals from your property.
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Cleanup</strong>: We will then clean and disinfect
                    the areas where the animals were living to prevent the
                    spread of disease.
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Prevention</strong>: We will then work with you to
                    create a customized plan to remove the animals and seal up
                    entry points to prevent them from returning.
                  </li>
                </ul>

                <p>
                  No matter what your situation is, we can help you find a
                  solution that works for you.
                </p>
                <h2>Get Your Free Estimate</h2>
                <p>
                  Ready for our{' '}
                  <AnchorText type="internal" to="wildlife-control">
                    wildlife control services
                  </AnchorText>
                  ?
                </p>
                <p>
                  Call us today or fill out our form for your FREE estimate.
                  We'll be happy to answer any of your questions and help you
                  get started.
                </p>
              </article>
            </div>
            <div className="lg:col-span-5 lg:h-full lg:w-full">
              {/* <Sidebar sidebarType={sidebarType} /> */}
              <aside className="hidden pt-12 lg:flex lg:h-full lg:pt-0">
                <FadeIn classes="space-y-20 w-full lg:h-full">
                  {/* <SidebarListServices /> */}
                  <div className="space-y-6 lg:sticky lg:top-44">
                    <FormHero formName="form-sidebar" />
                  </div>
                </FadeIn>
              </aside>
            </div>
          </div>
        </div>
      </Section>
      {/* SECTION: residential services */}
      <Section
        padding="lg"
        bgColor={neutral?.lighter?.color}
        h2Color={neutral?.darker?.color}
        h3Color={primary?.dark?.color}
        $spanColor={accent?.light?.color}
      >
        <Container classes="text-center">
          <StyledTagline
            $spanColor={accent?.dark?.color}
            className="text-base font-semibold uppercase tracking-wider"
          >
            {page?.residentialHeaderTagline}
          </StyledTagline>
          <h2 className="mx-auto mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl lg:max-w-xl">
            {page?.residentialHeaderHeadline}
          </h2>
          <div className="mx-auto mt-6 max-w-prose text-xl text-zinc-600">
            {page?.residentialHeaderParagraph}
          </div>
          <Grid classes="mt-20 gap-3 grid-cols-2 lg:grid-cols-4">
            {services
              .filter((service) => service?.tag?.title === 'residential')
              .map((service) => (
                <AnchorText to={service?.link?.slug?.current}>
                  <Col
                    key={service?.name}
                    classes="pt-6 relative w-full mx-auto px-4 sm:px-6 lg:px-8"
                  >
                    <Container padding="none" classes="">
                      <div className="h-full w-full rounded-full border-2 border-red-400 bg-white object-cover p-8 shadow-2xl transition hover:scale-105 lg:p-12">
                        <div className="rounded-full">
                          <GatsbyImage
                            image={service?.image?.asset?.gatsbyImageData}
                            className="mx-auto h-full w-full object-cover"
                            alt={service?.image?.alt}
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </Container>
                    <div className="break-word relative z-10 text-center">
                      <h3 className="mt-8 text-xl font-medium lg:text-2xl">
                        {service?.name}
                      </h3>
                      <p className="mt-5 mb-8 text-left text-base text-zinc-500">
                        <SanityBlockContent blocks={service?._rawDescription} />
                      </p>
                    </div>
                  </Col>
                </AnchorText>
              ))}
          </Grid>
        </Container>
      </Section>
      {/* SECTION: residential details */}
      <Section padding="lg" bgColor={neutral?.white?.color}>
        <div className="mx-auto max-w-7xl">
          <div className="grid grid-cols-1 gap-x-24 lg:grid-cols-12">
            <div className="lg:col-span-7">
              <article className="sanity-headlines space-y-6 px-4 text-lg lg:px-0">
                <h2>Residential Pest Control Services</h2>
                <p>
                  Here at Pilgrim Pest Professionals, we offer various{' '}
                  <AnchorText type="internal" to="residential">
                    residential pest control services
                  </AnchorText>{' '}
                  ranging from one-time treatments to long-term pest management
                  plans. No matter your needs, we have a solution that will work
                  for you.
                </p>
                <h3>One-Time Pest Treatment</h3>
                <p>
                  You should consider this option if you have a specific issue
                  that requires immediate attention. Our team will come in and
                  resolve the issue, and you will not have to worry about it
                  again.
                </p>
                <h3>Pest Management For Specific Pests</h3>
                <p>
                  Depending on the type of pest you are experiencing, we can
                  tailor a plan that addresses your problems. Our team will
                  ensure that the problem is remedied and that you are protected
                  against that type of pest in the future.
                </p>
                <h3>Long-Term Pest Management</h3>
                <p>
                  This is a great option if you want to be proactive about
                  preventing pests from becoming a problem in your home.
                </p>
                <ul className="fa-ul text-left">
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Termite treatments</strong> include excellent
                    yearlong coverage options!
                  </li>
                </ul>
                <p>
                  We will work with you to create a plan that suits your needs
                  and ensure your home is protected from pests.
                </p>
                <h3>Recreational Vehicle Preventative Treatments</h3>
                <p>
                  Do you dread opening up your boat or RV and discovering the
                  damage rodents may have caused during the winter?
                </p>
                <p>
                  We have excellent rodent preventive treatments applied in the
                  fall just before you close up your recreational vehicle for
                  the winter.
                </p>
                <h3>Guaranteed Pest Control Results</h3>
                <p>
                  If you are interested in learning more about our residential
                  pest control services, please contact us today. We would be
                  happy to answer any questions and help you find the perfect
                  solution for your needs.
                </p>
                <ul className="fa-ul text-left">
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>6-Month</strong> rodent warranty
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>12-Month</strong> rodent warranty
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Quarterly</strong> services available
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Annual</strong> guarantee on carpenter ant coverage
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Complete</strong> yearlong coverage options
                    available for landlords
                  </li>
                </ul>
                <h2>Residential Wildlife Removal Services</h2>
                <p>
                  We understand that having wildlife in or around your home can
                  be stressful and a danger to family and pets.
                </p>
                <p>
                  That’s why we offer comprehensive{' '}
                  <AnchorText type="internal" to="residential">
                    residential wildlife removal services
                  </AnchorText>{' '}
                  to take care of the problem quickly, efficiently, and
                  humanely.
                </p>
                <ul className="fa-ul text-left">
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Inspection</strong>: Our experienced and certified
                    technicians will first conduct a thorough inspection of your
                    property to identify the type of animal and how it’s getting
                    in.
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Removal</strong>: Our technicians are trained in the
                    latest and most effective removal techniques. We also use
                    state-of-the-art equipment to safely and humanely remove the
                    animals from your property.
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Cleanup</strong>: We will then clean and disinfect
                    the areas where the animals were living to prevent the
                    spread of disease.
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Prevention</strong>: We will then work with you to
                    create a customized plan to remove the animals and seal up
                    entry points to prevent them from returning.
                  </li>
                </ul>
                <p>
                  If you’re dealing with a wildlife problem, don’t hesitate to
                  give us a call. We’re here to help and answer any of your
                  questions!
                </p>

                <p>
                  No matter what your situation is, we can help you find a
                  solution that works for you.
                </p>

                <p>
                  So if you're looking for a reliable, affordable, and
                  professional wildlife control company to take care of your
                  residential needs, look no further than Pilgrim Pest
                  Professionals.
                </p>
                <h2>Get Your Free Estimate</h2>
                <p>
                  Ready for our{' '}
                  <AnchorText type="internal" to="residential">
                    residential pest & wildlife control services
                  </AnchorText>
                  ?
                </p>
                <p>
                  Call us today or fill out our form for your FREE estimate.
                  We'll be happy to answer any of your questions and help you
                  get started.
                </p>
              </article>
            </div>
            <div className="lg:col-span-5 lg:h-full lg:w-full">
              {/* <Sidebar sidebarType={sidebarType} /> */}
              <aside className="hidden pt-12 lg:flex lg:h-full lg:pt-0">
                <FadeIn classes="space-y-20 w-full lg:h-full">
                  {/* <SidebarListServices /> */}
                  <div className="space-y-6 lg:sticky lg:top-44">
                    <FormHero formName="form-sidebar" />
                  </div>
                </FadeIn>
              </aside>
            </div>
          </div>
        </div>
      </Section>
      {/* SECTION: commercial services */}
      <Section
        padding="lg"
        bgColor={neutral?.lighter?.color}
        h2Color={neutral?.darker?.color}
        h3Color={primary?.dark?.color}
        $spanColor={accent?.light?.color}
      >
        <Container classes="text-center">
          <StyledTagline
            $spanColor={accent?.dark?.color}
            className="text-base font-semibold uppercase tracking-wider"
          >
            {page?.commercialHeaderTagline}
          </StyledTagline>
          <h2 className="mx-auto mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl lg:max-w-xl">
            {page?.commercialHeaderHeadline}
          </h2>
          <div className="mx-auto mt-6 max-w-prose text-xl text-zinc-600">
            {page?.commercialHeaderParagraph}
          </div>
          <Grid classes="mt-20 gap-3 grid-cols-2 lg:grid-cols-4">
            {services
              .filter((service) => service?.tag?.title === 'industry')
              .map((service) => (
                <AnchorText to={service?.link?.slug?.current}>
                  <Col
                    key={service?.name}
                    classes="pt-6 relative w-full mx-auto px-4 sm:px-6 lg:px-8"
                  >
                    <Container padding="none" classes="">
                      <div className="h-full w-full rounded-full border-2 border-red-400 bg-white object-cover p-8 shadow-2xl transition hover:scale-105 lg:p-12">
                        <div className="rounded-full">
                          <GatsbyImage
                            image={service?.image?.asset?.gatsbyImageData}
                            className="mx-auto h-full w-full object-cover"
                            alt={service?.image?.alt}
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </Container>
                    <div className="break-word relative z-10 text-center">
                      <h3 className="mt-8 text-xl font-medium lg:text-2xl">
                        {service?.name}
                      </h3>
                      <p className="mt-5 mb-8 text-left text-base text-zinc-500">
                        <SanityBlockContent blocks={service?._rawDescription} />
                      </p>
                    </div>
                  </Col>
                </AnchorText>
              ))}
          </Grid>
        </Container>
      </Section>
      {/* SECTION: commercial details */}
      <Section padding="lg" bgColor={neutral?.white?.color}>
        <div className="mx-auto max-w-7xl">
          <div className="grid grid-cols-1 gap-x-24 lg:grid-cols-12">
            <div className="lg:col-span-7">
              <article className="sanity-headlines space-y-6 px-4 text-lg lg:px-0">
                <h2>Commercial Pest & Wildlife Control Services</h2>
                <p>
                  If you're a business owner, you know that keeping your
                  property pest-free is essential to:
                </p>
                <ul className="sanity-ul">
                  <li>Maintaining a favorable reputation</li>
                  <li>Preventing costly damages</li>
                  <li>Posing health risks to your staff & customers</li>
                </ul>
                <p>
                  At Pilgrim Pest Professionals, we provide complete{' '}
                  <AnchorText to="commercial">
                    commercial pest control services
                  </AnchorText>{' '}
                  to protect your business from pests of all types.
                </p>
                <p>
                  We have the experience and expertise to handle any situation,
                  and we'll work diligently to keep your property pest-free.{' '}
                </p>
                <h3>Meet Local Board Of Health Pest Management Requirements</h3>
                <p>
                  If you're in the food service industry, you know that the
                  local Board of Health requirements for pest management are
                  stringent. And for good reason- no one wants to eat food
                  that's been contaminated by pests!
                </p>
                <p>
                  That's where our commercial pest control services come in. We
                  can make sure that your restaurant, grocery store, or food
                  processing facility meets all of the local Board of Health's
                  requirements for pest management.
                </p>
                <h3>Routine Monthly Services With Guaranteed Results</h3>
                <p>
                  Our routine monthly services are guaranteed to get rid of any
                  existing pests and keep new ones from coming in. Plus, our
                  service is affordable and can be tailored to fit the unique
                  needs of your business.
                </p>
                <p>
                  Whether you have an office, retail store, or restaurant, we
                  can create a custom pest control plan that will work for you.
                </p>
                <h3>Complete Yearlong Coverage</h3>
                <p>
                  Our complete yearlong coverage ensures that you'll be
                  protected no matter when problems arise. And if we do need to
                  come back out for retreatment, there's no extra charge - it's
                  all included in your initial investment.
                </p>
                <p className="font-semibold italic">
                  This peace of mind is invaluable.
                </p>
                <p>
                  If you are interested in learning more about our commercial
                  pest control services, please contact us today. We would be
                  happy to answer any questions and help you find the perfect
                  solution for your needs.
                </p>
                <h2>Our Commercial Wildlife Removal Services</h2>
                <p>
                  We understand that having wildlife in or around your business
                  can be stressful and a danger to customers and employees.
                </p>
                <p>
                  That’s why we offer comprehensive{' '}
                  <AnchorText to="commercial">
                    commercial wildlife removal services
                  </AnchorText>{' '}
                  to take care of the problem quickly, efficiently, and
                  humanely.
                </p>

                <ul className="fa-ul text-left">
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Inspection</strong>: Our experienced and certified
                    technicians will first conduct a thorough inspection of your
                    property to identify the type of animal and how it’s getting
                    in.
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Removal</strong>: Our technicians are trained in the
                    latest and most effective removal techniques. We also use
                    state-of-the-art equipment to safely and humanely remove the
                    animals from your property.
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Cleanup</strong>: We will then clean and disinfect
                    the areas where the animals were living to prevent the
                    spread of disease.
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Prevention</strong>: We will then work with you to
                    create a customized plan to remove the animals and seal up
                    entry points to prevent them from returning.
                  </li>
                </ul>

                <p>
                  If you’re dealing with a wildlife problem, don’t hesitate to
                  give us a call. We’re here to help and answer any of your
                  questions!
                </p>
                <h2>Get Your Free Estimate</h2>
                <p>
                  If you are interested in learning more about our{' '}
                  <AnchorText to="commercial">
                    commercial pest control & wildlife removal services
                  </AnchorText>{' '}
                  , please contact us today. We look forward to hearing from
                  you!
                </p>
                <ul className="fa-ul text-left">
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Guaranteed Results</strong>: We guarantee that your
                    pest & wildlife problems will be solved and that you'll be
                    satisfied with our services.
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Safe & Effective</strong>: Our team of experts is
                    experienced in safely and humanely removing all types of
                    pests and wildlife, from termites and fleas to raccoons and
                    skunks.
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Professional & Experienced</strong>: We have been
                    providing commercial pest control & wildlife removal
                    services for over 10 years. We are experts in our field and
                    take pride in our work.
                  </li>
                </ul>
                <p>
                  No matter what type of problem you're having with wildlife, we
                  can help! Give us a call today for a free consultation.
                </p>
              </article>
            </div>
            <div className="lg:col-span-5 lg:h-full lg:w-full">
              {/* <Sidebar sidebarType={sidebarType} /> */}
              <aside className="hidden pt-12 lg:flex lg:h-full lg:pt-0">
                <FadeIn classes="space-y-20 w-full lg:h-full">
                  {/* <SidebarListServices /> */}
                  <div className="space-y-6 lg:sticky lg:top-44">
                    <FormHero formName="form-sidebar" />
                  </div>
                </FadeIn>
              </aside>
            </div>
          </div>
        </div>
      </Section>
      <Section padding="lg" bgColor={neutral?.light?.color}>
        <Container classes="text-center mx-auto">
          <div className="pb-24">
            <StyledTagline
              $spanColor={accent?.dark?.color}
              className="text-base font-semibold uppercase tracking-wider"
            >
              {page?.company}
            </StyledTagline>
            <h2 className="mx-auto mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl lg:max-w-xl">
              {page?.keyword1} & {page?.keyword2} Services in{' '}
              {page?.cityStateZip}
            </h2>
            {/* <div className="mt-6 max-w-prose mx-auto text-xl text-zinc-600">
              TEST
            </div> */}
          </div>
          <iframe
            src={page?.mapEmbed}
            width="100%"
            height="450"
            loading="eager"
            name={`${page?.company} is a ${page?.keyword1} company in ${page?.cityState}`}
          ></iframe>
        </Container>
      </Section>
    </Layout>
  );
};

export default LocationTemplate;
